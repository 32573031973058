import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { seo, intro, main } from '../content/oferty-pracy'
import Layout from '../components/Layout'
import Intro from '../components/Intro'
import { useLangContext } from '../context/lang.context'
import Seo from '../components/Seo'
import Main from '../components/Main'
import Jobs from '../sections/Jobs'
import Jubileusze from '../sections/kariera/Jubileusze'

const OfertyPracy = () => {
  const { lang } = useLangContext()

  const { image } = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/oferty-pracy.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        image={'images/oferty-pracy.jpg'}
        url={seo.url}
      />
      <Intro data={intro} image={image.childImageSharp} />
      <Main data={main} h={1} />
      <Jobs />
      <Jubileusze />
    </Layout>
  )
}

export default OfertyPracy
