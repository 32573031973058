export const seo = {
  url: 'oferty-pracy',
  title: {
    pl: 'Oferty pracy - transport, spedycja, logistyka',
    en: 'Job offers - transport, forwarding, logistics',
  },
  desc: {
    pl: 'Poznaj najnowsze oferty pracy w branży TSL. Znajdź oddział w Twoim mieście i dołącz do najbardziej rozpoznawalnej firmy logistycznej w Polsce!',
    en: 'Find out about the latest job offers in the TSL industry. Find a branch in your city and join the most recognizable logistics company in Poland!',
  },
  keywords: [
    'omida',
    'logistics',
    'transport',
    'logistyka',
    'praca',
    'oferty pracy',
    'kariera',
    'spedytor',
  ],
}

export const intro = {
  title: {
    pl: 'Oferty Pracy TSL',
    en: 'Job Offers in TSL',
  },
  desc: {
    pl: 'Poznaj najnowsze oferty pracy w branży TSL. Dołącz do najbardziej rozpoznawalnej firmy logistycznej w Polsce!',
    en: 'Find out about the latest job offers in the TSL industry. Join the most recognizable logistics company in Poland!',
  },
  button: {
    text: {
      pl: 'Przeglądaj',
      en: 'Explore',
    },
    action: 'SCROLL',
  },
}

export const main = {
  body: [
    {
      headline: {
        pl: 'Oferty pracy w logistyce. Zostań spedytorem!',
        en: 'Job offers in logistics. Become a forwarder!',
      },
      texts: [
        {
          pl: '<span>Jesteśmy dużą rozpoznawalną firmą transportową. Realizujemy <strong>stabilne warunki współpracy</strong>, <strong>szkolenia branżowe</strong> i pracę w jednej z <strong>najszybciej rozwijających się</strong> polskich firm transportowych. Stań się częścią zespołu Omida Logistics. Skontaktuj się z nami.</span>',
          en: '<span>We are a large recognizable transport company. We offer <strong>stable cooperation conditions</strong>, <strong>industry training</strong> and work in one of the <strong>fastest growing</strong> Polish transport companies. Become part of the Omida Logistics team. Please contact us.</span>',
        },
      ],
    },
    {
      headline: {
        pl: 'Chcesz otworzyć oddział spedycyjny w Twoim mieście?',
        en: 'Would you like to open a forwarding branch in your city?',
      },
      texts: [
        {
          pl: 'Skontaktuj się z nami i wspólnie otwórzmy oddział w Twojej miejscowości. Jesteśmy w dużych miastach i mniejszych miasteczkach. Sprawdź istniejące oddziały TSL poniżej.',
          en: `Contact us and let's open a branch in your town together. We are in large cities and smaller towns. Check out the existing TSL branches below.`,
        },
      ],
    },
  ],
}
