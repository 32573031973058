import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Tiles from '../components/Tiles'
// import { useLangContext } from '../context/lang.context'

const Jobs = ({ minimal }) => {
  // const { lang } = useLangContext()
  const { jobs } = useStaticQuery(graphql`
    {
      jobs: allContentfulPraca(sort: { fields: city }) {
        nodes {
          title
          slug
          node_locale
          city
          image {
            gatsbyImageData(width: 480, quality: 100, formats: [JPG])
          }
        }
      }
    }
  `)

  const links = jobs.nodes
    .filter((node) => node.node_locale === 'pl')
    .map((node) => ({
      slug: 'kariera/' + node.slug,
      name: node.city,
      image: node.image,
      subtitle: node.title,
    }))

  return <Tiles data={links} marginMinimal={minimal} />
}

export default Jobs
